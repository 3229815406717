.register-actions {
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
}

.register-action-ok {
    width: 60px;
}

.register-action-cancel {
    color: #565656;
    margin-right: 30px;
    cursor: pointer;
    height: 45px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: PT Sans;
}

.register-action-cancel:hover {
    color: white;
}

.register-container {
    padding: 0;
}

.register-title {
    font-size: 30px;
}

.register-email {
    margin-top: 15px;
}

.register-message {
    margin: 5px 0 30px 0;
    font-size: 18px;
}

.register-email-input {
    font-family: "PT Sans";
}
