.home-contact-wrapper {
    text-align: left;
    padding-top: 30.9px;
    padding-bottom: 30.9px;
}

.home-contact-headline {
    font-family: Forza;
    font-size: 36.2px;
    color: #252525;
    padding: 0px 0px 38px 0px;
    margin: 0;
    margin-left: -14px;
}

.home-contact-button-wrapper {
    display: flex;
    /*padding-left: 55px;*/
    margin-left: -14px;
}

.home-contact-options {
    margin: 50px 0 30px 0;
    background-color: #d13e21;
    padding: 10px 0 10px 0;
    width: 103%;
    transform: translateX(-12px);
}

.home-contact-options-wrapper {
    padding-left: 72px;
    display: flex;
}

.home-contact-option {
    display: flex;
    color: white;
    font-size: 14px;
    line-height: 26px;
}

.home-contact-option-link {
    text-decoration: none;
}

.home-contact-option-icon {
    padding: 5px 10px 0 0;
}

.home-contact-option-icon-img {
    height: 17px;
    width: 17px;
    fill: #000000;
}

.home-contact-social-icon {
    height: 25px;
    width: auto;
    margin-right: 25px;
    cursor: pointer;
}

.home-contact-follow-us {
    font-size: 22px;
    margin: 40px 0 15px;
}

@media only screen and (max-width: 1199px) {

    .home-contact-wrapper {
        padding-top: 25.9px;
        padding-bottom: 25.9px;
    }

    .home-contact-headline {
        font-size: 24px;
        /*padding: 10px 0px 21px 0px;*/
        height: 33.2px;
        line-height: 1.59;
        margin-left: -14px;
    }

    .home-contact-button-wrapper {
        margin-left: -14px;
    }

    .home-contact-options {
        margin-top: 28.5px;
        margin-bottom: 36px;
        transform: translateX(-12px);
    }

    .home-contact-options-wrapper {
        padding-left: 32px;
    }

    .home-contact-option {
        font-size: 12px;
        margin-right: 12px;
    }

    .home-contact-social-icon {
        height: 20px;
        margin-right: 20px;
    }

    .home-contact-follow-us {
        font-size: 20px;
        margin: 0px 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .home-contact-wrapper {
        padding-top: 16.9px;
        padding-bottom: 16.9px;
    }

    .home-contact-options {
        transform: translateX(0);
        width: 100%;
    }

    .home-contact-headline {
        margin-left: 0px;
        padding-bottom: 25px;
    }

    .home-contact-button-wrapper {
        margin-left: 0px;
    }

    .home-contact-social-icon {
        height: 20px;
        margin-right: 20px;
    }

    .home-contact-follow-us {
        font-size: 20px;
        margin: 0px 0 15px;
    }
}
