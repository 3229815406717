.cart-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    transition: all 0.2s linear;
    transform: translateX(100%);
}

.cart-container-show {
    transform: translateX(0);
    box-shadow: -2px 0px 5px #d13e21;
}

.cart-padding {
    padding: 10px 24px 0px 20.5px;
}

.cart-header {
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    margin: 10px 0 0px 0;
}

.cart-close-button {
    width: 30px;
	height: 30px;
    margin-top: -5px;
    transition: all 0.1s linear;
}

.cart-close-button:hover {
    width: 35px;
	height: 35px;
}

.cart-close-button-container {
    /*margin-left: 95px;*/
    cursor: pointer;
    height: 45px;
    width: 45px;
}

.cart-item-top {
    margin: 20px 0 10px 0;
}

.cart-item-title {
    text-align: left;
    font-weight: bold;
}

.cart-item-quantity {
    margin: 0 15px 0 15px;
}

.cart-subtotal-divider {
    height: 1px;
    background-color: white;
    /*margin-bottom: 24px;*/
}

.cart-subtotal-label {
    text-transform: uppercase;
    text-align: left;
    font-size: 12px;
}

.cart-disclaimer {
    font-size: 11px;
    margin-top: 10px;
}

.cart-checkout-btn {
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-indicator {
    position: fixed;
    right: 0;
    top: 20%;
    height: 50px;
    width: 50px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    transition: all 0.1s linear;
    transform: translateX(100%);
}

.cart-indicator-show {
    transform: translateX(0%);
}

.cart-indicator:hover {
    height: 55px;
    width: 55px;
}

.cart-item-description {
    font-size: 12px;
    text-align: left;
    padding-bottom: 10px;
}

.cart-item-remove {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    height: 45px;
    cursor: pointer;
}

.cart-item-remove:hover {
    color: #000000;
}

.cart-continue-shopping {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    color: white;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 767px) {

    .cart-header {
        font-size: 20px;
    }
}