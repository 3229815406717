.product-we-tested-container {
    /*align-items: center;*/
    justify-content: flex-start;
    background-image: url(../img/maker-2-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    height: 521px;
    text-align: left;
    color: white;
}

.product-we-tested-headline {
    font-family: Forza;
    font-size: 36px;
    color: white;
    word-break: normal;
    line-height: 38.4px;
    margin: 162px 0 12px 0;
    width: 437px;
}

.product-we-tested-content {
    width: 515px;
    font-size: 14.1px;
    line-height: 23.7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media only screen and (max-width: 1199px) {

    .product-we-tested-container {
        height: 600px;
        background-image: url(../img/tablet/maker2_bg_converted@2x.jpg);
    }

    .product-we-tested-content {
        font-size: 12px;
        width: 320px;
    }

    .product-we-tested-headline {
        margin-top: 234px;
        font-size: 28px;
        width: 377px;
    }

}

@media only screen and (max-width: 767px) {

    .product-we-tested-container {
        height: 333px;
        background-image: url(../img/phone/maker2_bg_converted@3x.jpg);
    }

    .product-we-tested-headline {
        font-size: 24px;
        margin-top: 102px;
        width: auto;
        line-height: 1.1;
    }

    .product-we-tested-content {
        width: 300.1px;
    }

}
