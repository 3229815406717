.home-product-summary {
    height: 552px;
    background-image: url(../img/blueprint-about.jpg);
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.home-product-summary-info-container {
    background-color: #252525;
    padding: 0 20px 30px 20px;
    width: 515px;
}

.home-product-symmary-info-container-logo {
    margin-top: -25px;
}

.home-product-symmary-info-container-headline {
    font-family: Forza;
    color: #eb4727;
    margin-top: 28px;
}

.home-product-symmary-info-container-headline-top {
    font-size: 28px;
    line-height: 1.179;
    margin: 0;
}

.home-product-symmary-info-container-headline-bottom {
    font-size: 36px;
    line-height: 0.722;
    margin: 5px 0 0 0;
}

.home-product-summary-info-content {
    color: white;
    margin-top: 15px;
    font-size: 14px;
    line-height: 24.1px;
}

.home-product-learn-more-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.home-product-summary-logo {
    width: 45px;
    height: 60px;
}

@media only screen and (max-width: 1199px) {

    .home-product-summary {
        height: 600px;
        background-image: url(../img/tablet/blueprint-about-converted@2x.jpg);
    }

    .home-product-summary-info-container {
        width: 412.6px;
    }

    .home-product-summary-logo {
        width: 29px;
        height: 38px;
    }

    .home-product-symmary-info-container-logo {
        margin-top: -15px;
    }

    .home-product-symmary-info-container-headline-top {
        font-size: 24px;
    }

    .home-product-symmary-info-container-headline-bottom {
        font-size: 30px;
    }

    .home-product-summary-info-content {
        font-size: 12px;
    }

    .home-product-symmary-info-container-headline {
        margin-top: 9.2px;
    }
}

@media only screen and (max-width: 767px) {
    .home-product-summary {
        height: auto;
        padding-top: 68px;
        padding-bottom: 41px;
    }

    .home-product-summary-info-container {
        width: 100%;
    }

    .home-product-symmary-info-container-headline-top {
        font-size: 18px;
    }

    .home-product-symmary-info-container-headline-bottom {
        font-size: 24px;
    }

    .home-product-summary-info-content {
        line-height: 1.5;
    }
}
