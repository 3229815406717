.sliding-drawer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 251px;
    transition: all 0.2s linear;
    transform: translateX(-100%);
    overflow-y: scroll;
}

.sliding-drawer-padding {
    padding: 10px 24px 0px 20.5px;
}

.sliding-drawer-header {
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    margin-top: 10px;
}

.sliding-drawer-close-button {
    width: 19.6px;
	height: 16px;
    transition: all 0.1s linear;
}

.sliding-drawer-close-button:hover {
    width: 25px;
	height: 20px;
}

.sliding-drawer-close-button-container {
    cursor: pointer;
    height: 45px;
    width: 45px;
}

.sliding-drawer-out {
    transform: translateX(0);
    box-shadow: 2px 0px 5px #d13e21;
}

.sliding-drawer-nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.sliding-drawer-nav-link {
    font-size: 16px;
    color: white;
    text-decoration: none;
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    cursor: pointer;
    height: 45px;
    align-items: center;
}

.sliding-drawer-nav-link:hover {
    color: black;
}

.sliding-drawer-nav-link a {
    text-decoration: none;
    color: white;
    width: 100%;
}

.sliding-drawer-nav-link a:hover {
    text-decoration: none;
    color: black;
}
