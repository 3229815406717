.productlisting-container {
    padding: 30px 30px 30px 30px;
}

.productlisting-title {
    font-size: 30px;
    /*margin-bottom: 50px;*/
    color: #000000;
    padding: 20px 20px 5px;
    /*min-width: 800px;*/
}

.product-item-container {
    margin-top: 30px;
    margin-bottom: 30px;
    -ms-flex: 1;
    display: block;
}

.product-item-image-container {
    /*margin-right: 25px;*/
    height: 100%;
}

.product-item-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.product-item-add-button-container {
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: flex-end;
    justify-content: flex-end;
    /*padding-left: 50px;*/
}

.product-item-add-button {
    width: 82%;
}

.product-item-quantity {
    margin-bottom: 10px;
}

.product-item-title {
    font-weight: bold;
    font-size: 20px;
}

.product-item-description {
    margin-top: 10px;
}

.product-item-price {
    font-weight: bold;
    margin-top: 10px;
}

.productlisting-actions {
    justify-content: flex-end;
    align-items: center;
    /*margin-top: 50px;*/
}

.productlisting-action-ok {
    text-transform: uppercase;
    cursor: pointer;
    height: 45px;
    width: 100px;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 20px;
}

.productlisting-action-ok:hover {
    color: #222222;
}

.productlisting-cart-icon {
    transition: 0.2s linear all;
    height: auto;
    width: auto;
}

.productlisting-cart-icon:hover {
    /*height: 30px;*/
    /*width: 30px;*/
}

.productlisting-cart-quantity {
    transition: 0.2s linear all;
    font-size: 20px;
    cursor: pointer;
}

.productlisting-cart-quantity-large {
    font-size: 28px;
    color: white;
}

.productlisting-cart-quantity:hover {
    /*font-size: 24px;*/
}

.productlisting-divider {
    height: 15px;
}

.productlisting-divider-bottom {
    border:none;
    border-bottom: 1px solid #1f1209;
    box-shadow: 0 5px 10px -5px #333;
    margin: 0px auto 10px;
}

.productlisting-divider-top {
    border:none;
    border-top: 1px solid #1f1209;
    box-shadow: 0 -5px 10px -5px #333;
    margin: 0px auto 10px;
}

.productlisting-action {
    width: 100%;
}

@media only screen and (max-width: 1199px) {
    .productlisting-title {
        /*min-width: 700px;*/
    }
}

@media only screen and (max-width: 767px) {

    .productlisting-title {
        font-size: 20px;
        /*margin-bottom: 30px;*/
        /*min-width: 90%;*/
    }

    .product-item-image {
    }

    .product-item-add-button-container {
        /*flex-direction: row;*/

    }

    .product-item-add-button {
        width: 90%;
    }

    .product-item-quantity {
        margin-bottom: 0;
    }

    .productlisting-cart-quantity-large {
        font-size: 24px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .productlisting-title {
        min-width: 800px;
    }
}

@media all and (max-width: 1199px) and (-ms-high-contrast: none), (-ms-high-contrast: active)  {
    /* IE10+ CSS styles go here */

    .productlisting-title {
        min-width: 700px;
    }
}

@media all and (max-width: 767px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .productlisting-title {
        min-width: 300px;
    }
}