.home-about-wrapper {
    padding-top: 30.9px;
    padding-bottom: 30.9px;
}

.home-about-content {
    color: white;
    font-size: 14px;
    line-height: 24.1px;
    margin-top: 24px;
}

.home-about-logos {
    display: flex;
    margin-top: 35px;
    color: white;
    font-size: 8px;
}

.home-about-logo {
    margin-right: 48px;
}

.home-about-ll-logo {
    width: 259px;
}

.home-about-logo-nfpa {
    width: 44px;
    height: 51px;
}

.home-about-logo-nfpa-member {
    margin-left: 4px;
}

.home-about-logo-fama {
    width: 104px;
    height: 51px;
}

@media only screen and (max-width: 1199px) {

    .home-about-ll-logo {
        width: 193px;
	    height: 30px;
    }

    .home-about-content {
        font-size: 12px;
        line-height: 1.8;
        margin-top: 12.6px;
    }

    .home-about-logos {
        margin-top: 15px;
    }

    .home-about-logo {
        margin-right: 30.8px;
    }

    .home-about-logo-nfpa {
        width: 28.7px;
        height: 32.6px;
    }

    .home-about-logo-fama {
        width: 66.5px;
        height: 32.6px;
    }

    .home-about-logo-nfpa-member {
        margin-left: 4px;
        font-size: 5px;
    }
}
