.ll-divider {
    height: 15px;
    width: 100%;
}

.ll-divider-top {
    border:none;
    border-bottom: 1px solid #1f1209;
    box-shadow: 0 5px 10px -5px #333;
    margin: 0px auto 10px;
}

.ll-divider-bottom {
    border:none;
    border-top: 1px solid #1f1209;
    box-shadow: 0 -5px 10px -5px #333;
    margin: 0px auto 10px;
}