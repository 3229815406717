.product-designed-by-container {
    /*align-items: center;*/
    justify-content: flex-end;
    background-image: url(../img/craftman-image-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    height: 521px;
    text-align: right;
    color: white;
}

.product-designed-by-headline {
    font-family: Forza;
    font-size: 36px;
    color: white;
    word-break: normal;
    line-height: 38.4px;
    margin: 145px 0 12px 0;
    width: 460px;
}

.product-designed-by-content {
    width: 515px;
    font-size: 14.1px;
    line-height: 23.7px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media only screen and (max-width: 1199px) {

    .product-designed-by-container {
        height: 600px;
        background-image: url(../img/tablet/craftman_image_bg_converted@2x.jpg);
    }

    .product-designed-by-content {
        font-size: 12px;
        width: 329.6px;
    }

    .product-designed-by-headline {
        margin-top: 213.8px;
        font-size: 28px;
        width: 350px;
    }

}

@media only screen and (max-width: 767px) {

    .product-designed-by-container {
        height: 333px;
        background-image: url(../img/phone/craftman_image_bg_converted@3x.jpg);
    }

    .product-designed-by-headline {
        font-size: 24px;
        width: 279.6px;
        line-height: 1.1;
        margin-top: 78px;
    }

    .product-designed-by-content {
        width: 279.6px;
    }

}
