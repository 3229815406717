.product-video-container {
    background-color: black;
    /*padding-bottom: 49px;*/
}

.product-video-headline {
    padding-top: 49px;
    margin-bottom: 10px;
    width: 500px;
}

.product-video-headline h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
}

.product-video-feature-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin: 35px 0 5px 0;
}

.product-video-feature-description {
    font-size: 14px;
    text-align: left;
}

.product-video-placeholder {
    font-size: 18px;
}

.product-video-img {
    /*height: 500px;*/
    width: 100%;
}

.product-video-feature-selected {
    color: #eb4727;
}

.product-video-feature {
    height: auto;
}

.product-video-features {
    /*margin-top: 50px;*/
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {

    .product-video-headline {
        padding-top: 29px;
        margin-bottom: 0px;
        width: auto;
    }

    .product-video-headline h2 {
        font-size: 18px;
    }

    .product-video-feature-title {
        font-size: 18px;
        color: #eb4727;
    }

    .product-video-feature-description {
        font-size: 12px;
    }

    .product-video-placeholder {
        /*height: 200px;*/
    }

    .product-video-feature-title {
        margin-top: 0px;
    }

    .product-video-img {
        height: 300px;
    }

    .product-video-feature-selected {
        /*overflow: visible;*/
        /*height: 100%;*/
        /*display: none;*/
        height: 80px !important;
    }

    .product-video-feature {
        /*display: none;*/
        overflow: hidden;
        /*height: auto;*/
        height: 0px;
    }

    .product-video-features {
        margin-bottom: 20px;
    }

}
