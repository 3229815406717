@font-face {
    font-family: 'Forza';
    src: url('../fonts/Forza-Black.eot');
    src: url('../fonts/Forza-Black.woff') format('woff'), url('../fonts/Forza-Black.ttf') format('truetype'), url('../fonts/Forza-Black.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ll-button {
    height: 36px;
    padding: 0 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 0.3px;
    user-select: none;
}

.ll-button-dark {
    color: white;
    background-color: #252525;
}

.ll-button-dark:hover {
    background-color: white;
    color: #252525;
    font-weight: bold;
}

.ll-button-orange {
    color: white;
    background-color: #eb4727;
}

.ll-button-orange:hover {
    background-color: white;
    color: #eb4727;
    font-weight: bold;
}

.ll-button-white {
    background-color: white;
    color: black;
    font-weight: bold;
}

.ll-button-white:hover {
    background-color: black;
    color: white;
}

.orange {
    color: #eb4727;
}

.orange-bg {
    background-color: #eb4727;
}

.white {
    color: #ffffff;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-left {
    text-align: left;
}

.align-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.center {
    align-items: center;
    justify-content: center;
}

.outter-padding {
    padding: 0 68px 0 68px;
}

.expand-arrow {
    height: 10px;
    width: 16px;
    cursor: pointer;
}

.pt-sans {
    font-family: 'PT Sans', sans-serif;
}

.forza {
    font-family: Forza;
}

.contact {
    background-color: #eb4727;
}

.flex-1 {
    flex: 1;
}

@media only screen and (max-width: 1199px) {

    .ll-button {
        font-size: 12.4px;
    }

    .outter-padding {
        padding: 0 26px 0 26px;
    }
}

@media only screen and (max-width: 767px) {
    .outter-padding {
        padding: 0 20px 0 20px;
    }
}
