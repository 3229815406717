.product-built-from-best-container {
    background-image: url(../img/ladder-product-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    height: 458px;
}

.product-built-from-best-info {
    /*background-color: rgba(6, 6, 6, 0.6);*/
    background: linear-gradient(to right, rgba(6, 6, 6, 0.6), rgba(6, 6, 6, 0));
    padding-top: 77px;
    padding-bottom: 77px;
    color: white;
    /*width: 382px;*/
    text-align: left;
}

.product-built-from-best-info ul {
    font-size: 14px;
    font-family: Arial;
    line-height: 2.14;
    padding-left: 0px;
    list-style: none;
}

.product-built-from-best-info li {
    font-family: 'PT Sans', sans-serif;
    padding-left: 15px;
    text-indent: -15px;
}

.product-built-from-best-info li::before {
    content: "•";
    color: #eb4727;
    padding-right: 10px;
}

.product-built-from-best-info-headline {
    font-family: Forza;
    font-size: 30.1px;
    margin: 0;
    width: 352px;
}

@media only screen and (max-width: 1199px) {

    .product-built-from-best-container {
        background-image: url(../img/tablet/ladder-product_bg_converted@2x.jpg);
        background-position: 50% 0%;
        height: 600px;
    }

    .product-built-from-best-info-headline {
        font-size: 24px;
        width: 249px;
    }

    .product-built-from-best-info {
        padding-top: 138px;
        background: linear-gradient(to right, rgba(6, 6, 6, 0.6), rgba(6, 6, 6, 0.6) 70%, rgba(6, 6, 6, 0));
    }

    .product-built-from-best-info ul {
        font-size: 12px;
    }

}

@media only screen and (max-width: 767px) {

    .product-built-from-best-container {
        height: auto;
    }

    .product-built-from-best-info {
        padding-top: 78px;
        padding-bottom: 78px;
    }

}
