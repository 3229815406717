.home-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s linear;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.home-container-hidden {
    transform: translateX(-100%);
}

.home-header {
    height: 540px;
    background-image: url(../img/hero-image.jpg);
    background-size: cover;
    background-position: 50% 95%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    position: relative;
    flex-direction: column;
}

.home-header-headline {
    width: 953px;
    margin-top: 205px;
}

.home-header-expand-arrow {
    margin-top: 150px;
}

.home-about {
    background-image: url(../img/home-about-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    text-align: left;
}



.home-bottom-bumper {
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 1199px) {

    .home-header {
        height: 800px;
        background-image: url(../img/tablet/hero-image-converted@2x.jpg);
    }

    .home-header-headline {
        width: 718px;
        margin-top: 290px;
    }

    .home-header-expand-arrow {
        margin-top: 300px;
    }

    .home-bottom-bumper-item {
        height: auto;
    }

    .home-about {
        background-image: url(../img/tablet/home-about-bg-converted@2x.jpg);
    }
}

@media only screen and (max-width: 767px) {

    .home-header {
        height: 390px;
        background-image: url(../img/phone/hero-image-converted@2x.jpg);
    }

    .home-header-headline {
        width: 293px;
	    height: 45px;
        margin-top: 177px;
    }

    .home-header-expand-arrow {
        margin-top: 129px;
    }
}
