.copyright {
    font-size: 12px;
    margin-left: -10px;
    color: #565656;
    background-color: #000000;
    padding: 10px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .copyright {
        font-size: 7px;
    }
}
