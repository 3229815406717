.home-intro {
    height: 530px;
    background-image: url(../img/home-product-bg.jpg);
    background-size: cover;
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-color: black;
}

.home-intro-headline {
    font-family: Forza;
	font-size: 27.9px;
	font-weight: 300;
	line-height: 0.68;
	text-align: center;
	color: #eb4727;
    padding-top: 80px;
    margin: 0;
}

.home-intro-feature {
    flex: 1;
    display: flex;
}

.home-intro-feature-wrapper {
    width: 303px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-intro-feature-headline {
    font-family: Forza;
	font-size: 36.2px;
	font-weight: 900;
	line-height: 1.05;
    color: white;
    margin: 0;
    padding-top: 152px;
}

.home-intro-feature-content {
    font-size: 14px;
    color: white;
    line-height: 1.73;
    margin-top: 12px;
    width: 295px;
}

.home-intro-feature-divider {
    width: 245px;
	height: 5px;
	background-color: #eb4727;
    margin-top: 16px;
}

.home-intro-feature-ladder-mobile {
    display: none;
}

.home-intro-feature-ladder-tablet {
    display: none;
}

@media only screen and (max-width: 1199px) {

    .home-intro {
        height: 600px;
        background-image: url(../img/tablet/smoke-bg-converted.jpg);
        background-position: 50% 50%;
        /*overflow*/
        position: relative;
    }

    .home-intro-headline {
        font-size: 24px;
        padding-top: 54px;
        padding-bottom: 20px;
        line-height: 1.25;
    }

    .home-intro-feature-headline {
        padding-top: 32.1px;
        font-size: 28px;
        font-weight: normal;
    }

    .home-intro-feature-content {
        width: 240px;
        font-size: 12px;
    }

    .home-intro-feature-divider {
        height: 2.5px;
        width: 156px;
    }

    .home-intro-feature-ladder-mobile {
        display: none;
    }

    .home-intro-feature-ladder-tablet {
        display: flex;
        padding-bottom: 0 !important;
    }

    .home-intro-feature-ladder-tablet-img {
        width: 661px;
    	height: 396px;
        margin-left: 200px;
        margin-top: -80px;
    }

    /*.home-intro-features {
        background: linear-gradient(to bottom, rgba(6, 6, 6, 0.6), rgba(6, 6, 6, 0));
        padding-bottom: 200px;
    }*/

}

@media only screen and (max-width: 767px) {
    .home-intro {
        height: auto;
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .home-intro-headline {
        font-size: 18px;
        padding-top: 33px;
        padding-bottom: 33px;
    }

    .home-intro-feature-headline {
        padding-top: 0px;
        font-size: 24px;
    }

    .home-intro-feature-content {
        width: 237px;
    }

    .home-intro-features {
        /*padding-bottom: 279px;*/
    }

    .home-intro-feature-ladder-tablet {
        display: none;
    }

    .home-intro-feature-ladder-mobile {
        display: flex;
        padding-bottom: 0 !important;
    }

    .home-intro-feature-ladder-mobile-img {
        height: 258px;
        margin-left: 150px;
    }
}
