.product-commitment-container {
    /*align-items: center;*/
    justify-content: flex-start;
    background-size: cover;
    background-position: 50% 50%;
    text-align: left;
    color: white;
    padding-bottom: 30px;
}

.product-commitment-headline {
    font-family: Forza;
    font-size: 30px;
    word-break: normal;
    line-height: 38.4px;
    margin: 50.5px 0 12px 0;
    width: 480px;
}

.product-commitment-content {
    width: 515px;
    font-size: 14.1px;
    line-height: 23.7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.product-commitment-made-in-murica {
    width: 80px;
    height: auto;
    margin-left: 30px;
}

@media only screen and (max-width: 1199px) {

    .product-commitment-content {
        font-size: 12px;
    }

    .product-commitment-container {
        height: 238.3px;
    }

    .product-commitment-headline {
        margin-top: 30.1px;
        width: 306.7px;
        font-size: 24px;
        line-height: 1.1;
        margin-bottom: 14px;
    }

}

@media only screen and (max-width: 767px) {

    .product-commitment-headline {
        margin-top: 34px;
        width: 281px;
        /*font-size: 20px;*/
    }

    .product-commitment-container {
        height: 250px;
    }
}
