.product-order-bumper {
    background-image: url(../img/cta-bg-converted.jpg);
    background-size: cover;
    background-position: 50% 50%;
    height: 86px;
}

.product-order-bumper-logo {
    width: 27px;
    height: 35px;
}

.product-order-bumper-content {
    margin: 0 30px 0 30px;
    font-family: Forza;
    font-size: 30px;
    color: #252525;
}

@media only screen and (max-width: 1199px) {

    .product-order-bumper {
        height: 65px;
    }

    .product-order-bumper-logo {
        width: 20px;
        height: 26px;
    }

    .product-order-bumper-content {
        font-size: 21.1px;
        margin-left: 26.1px;
    }

}

@media only screen and (max-width: 767px) {

    .product-order-bumper {
        display: none;
    }

}
