.product-container {
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    transform: translateX(100%);
    transition: all 0.3s linear;
    -webkit-overflow-scrolling: touch;
}

.product-container-show {
    transform: translateX(0);
}

.product-header {
    height: 545px;
    background-image: url(../img/product-hero-image.jpg);
    background-size: cover;
    background-position: 50% 98%;
    background-color: black;
}

.product-header-headline-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.product-header-productshot {
    width: 399px;
    height: 309px;
    margin-top: 167px;
}

.product-header-headline {
    margin: 249px 0 0 0;
    font-family: Forza;
    font-size: 37.6px;
    font-style: normal;
	font-stretch: normal;
	line-height: 1.44;
	letter-spacing: normal;
	color: #ffffff;
    width: 430px;
    text-align: left;
}

.product-video {
    height: 400px;
    font-family: Forza;
    background-color: black;
}

.product-commitment {
    background-image: url(../img/maker-detail-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
}

.product-expand-arrow {
    margin-top: 120px;
}

@media only screen and (max-width: 1199px) {

    .product-header {
        height: 800px;
        background-image: url(../img/tablet/product-hero-image-converted@2x.jpg);
        background-position: 50% 45%;
    }

    .product-header-productshot {
        margin-top: 153.8px;
    }

    .product-header-headline-container {
        align-items: center;
        justify-content: center;
    }

    .product-header-headline {
        margin-top: 490.1px;
        font-size: 36px;
        text-align: center;
    }

    .product-expand-arrow {
        margin-top: 80px;
    }

    .product-commitment {
        background-image: url(../img/tablet/maker_detail_bg_converted@3x.jpg);
    }

}

@media only screen and (max-width: 767px) {

    .product-header {
        height: 582px;
        background-image: url(../img/phone/product-hero-image-converted@3x.jpg);
    }

    .product-header-productshot {
        width: 284px;
        height: 223px;
        margin-top: 114px;
    }

    .product-header-headline {
        font-size: 26px;
        margin-top: 397px;
    }

    .product-expand-arrow {
        margin-top: 30px;
    }
}
