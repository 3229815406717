.nav-container {
    /*height: 80px;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    display: flex;
    align-items: center;
    text-align: left;
    transition: all 0.3s linear;
}

.nav-logo-container {
    /*margin: 30.5px 0 30.5px 0;*/
}

.nav-logo {
    width: 218px;
    height: 34.5px;
    cursor: pointer;
    transition: all 0.3s linear;
}

.nav-link-container {
    padding-right: 56px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-link {
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 26px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 0.3px;
    line-height: 1.4;
    font-weight: bold;
}

.subnav {
    color: white;
    text-transform: uppercase;
    font-family: 'PT Sans', sans-serif;
    letter-spacing: 0.3px;
    letter-spacing: 0.3px;
    line-height: 1.4;
    font-weight: bold;
    font-size: 14px !important;
}

.nav-link:hover {
    color: #eb4727;
}

.nav-menu-btn {
    display: none;
}

@media only screen and (max-width: 1199px) {

    .nav-container {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .nav-link {
        display: none;
    }

    .nav-menu-btn {
        display: flex;
        height: 44px;
        width: 44px;
        margin-right: 10px;
        cursor: pointer;
    }

    .nav-menu-btn-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
	    height: 16px;
    }
}

@media only screen and (max-width: 767px) {

    .nav-logo {
        width: 138px;
        height: 21.8px;
    }

    .nav-container {
        padding-top: 15.5px;
        padding-bottom: 10.5px;
    }
}
